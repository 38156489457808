import { useEffect, useState } from "react";

const useIframeResize = ({ workspaceDetails, site, content }) => {
  const [maxWidth, setMaxWidth] = useState("100%");
  const [disabledIframeExpansion, setDisabledIframeExpansion] = useState(false);

  useEffect(() => {
    const handleResizeMessage = (event) => {
      const data = event.data;
      if (data && data.method === "notifyFrameSize") {
        // Adjust the iframe height
        const iframe = document.getElementById("iframe");
        if (iframe) {
          iframe.height = `${data.params.height}px`;
        }
      }
    };

    const layout_type = workspaceDetails?.use_global_settings ? site?.layout_type : workspaceDetails?.layout_type;

    if (maxWidth !== "100%" && !disabledIframeExpansion && layout_type === "LEFT_NAVIGATION") {
      window.addEventListener("message", handleResizeMessage, { passive: true });
    }

    return () => {
      if (maxWidth !== "100%" && !disabledIframeExpansion && layout_type === "LEFT_NAVIGATION") {
        window.removeEventListener("message", handleResizeMessage);
      }
    };
  }, [workspaceDetails?.use_global_settings, maxWidth, disabledIframeExpansion, workspaceDetails?.layout_type, site?.layout_type]);

  useEffect(() => {
    if (content?.embed_type === "EMBED_APP") {
      setMaxWidth("100%");
    } else {
      if (workspaceDetails?.use_global_settings) {
        setMaxWidth(site?.max_width || workspaceDetails?.max_width || "100%");
      } else {
        setMaxWidth(workspaceDetails?.max_width || "100%");
      }
    }
  }, [workspaceDetails?.use_global_settings, workspaceDetails?.max_width, site?.max_width, content?.embed_type]);

  useEffect(() => {
    setDisabledIframeExpansion(workspaceDetails?.use_global_settings ? site?.disabled_iframe_expansion : workspaceDetails?.disabled_iframe_expansion || false);
  }, [workspaceDetails?.use_global_settings, workspaceDetails?.disabled_iframe_expansion, site?.disabled_iframe_expansion]);

  return { maxWidth };
};

export default useIframeResize;

/////////////////////////////////////////////////
/* Possible USE FOR MUTATIONS over uselisten?? */
/////////////////////////////////////////////////

// import { useContext, useEffect } from "react";
// import { SiteContext } from "src/context/site";
// import { WorkspaceContext } from "src/context/workspace";
// import { classNames } from "src/helpers/classNames";
// import useIframeResize from "src/hooks/useIframeResize";

// export default function DomoEmbedProgrammaticFiltering({ content }) {
//   const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
//   const { site } = useContext(SiteContext);
//   const { maxWidth } = useIframeResize({ workspaceDetails, site });

//   // Define the conditional maxWidth value
//   const conditionalMaxWidth = content?.embed_type === "EMBED_APP" ? "100%" : maxWidth;

//   useEffect(() => {
//     console.log("hello world?");

//     const handleAuthMessage = (event) => {
//       const data = event.data;
//       if (data) {
//         // const iframe = document.getElementById("iframe");
//         // iframe.contentWindow.postMessage("data", "https://public.domo.com");
//       }
//     };

//     const targetNode = document.getElementById("iframe");
//     if (!targetNode) {
//       console.warn("Target iframe not found");
//       return;
//     }

//     // Set up a MutationObserver to watch for DOM changes
//     const observer = new MutationObserver((mutations) => {
//       console.log(`mutations (${mutations.length}): `);
//       for (let mutation of mutations) {
//         console.log("mutation.attributeName: ", mutation.attributeName);

//         if (mutation.type === "childList" || mutation.type === "attributes") {
//           handleAuthMessage({ data: "mutation detected" }); // Trigger your logic here
//         }
//       }
//     });

//     // Observer options
//     const observerOptions = {
//       attributes: true, // Watch for attribute changes
//       childList: true, // Watch for addition/removal of child elements
//       subtree: true, // Watch the subtree of the target node
//     };

//     // Start observing
//     observer.observe(targetNode, observerOptions);

//     return () => {
//       // Cleanup the observer
//       observer.disconnect();
//     };
//   }, []);

//   return (
//     <>
//       <div className={classNames("relative h-full w-full", menuType === "horizontal" || menuType === "vertical" ? "" : "")}>
//         {content?.iframeHtml && (
//           <div
//             className="mx-auto h-full w-full overflow-auto"
//             style={{ maxWidth: conditionalMaxWidth }}
//             dangerouslySetInnerHTML={{ __html: content?.iframeHtml }}
//           />
//         )}
//       </div>
//     </>
//   );
// }
