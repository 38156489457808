import { ClockIcon, EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/form/Button";
import Input from "src/components/form/Input";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { apiUrl } from "src/config/host";
import { SiteContext } from "src/context/site";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";
import AuthDataLayout from "src/layouts/AuthDataLayout";
import auth from "src/services/auth";

const Login = () => {
  const navigate = useNavigate();
  const { redirectUrl } = useUrlQueryParams();
  const { site } = useContext(SiteContext);

  const [lockout, setLockout] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [disabledResendOtp, setDisabledResendOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [requireTwoFactor, setRequireTwoFactor] = useState(false);
  const [loadingTrackLogin, setLoadingTrackLogin] = useState(true);
  const [loadingSsos, setLoadingSsos] = useState(false);
  const [isPreloader, setIsPreloader] = useState(true);
  const [ssos, setSsos] = useState([]);
  const [otpSecond, setOtpSecond] = useState(0);
  const [resendOtpSecond, setResendOtpSecond] = useState(0);

  const loadSso = useCallback(() => {
    (async () => {
      if (!site?._id) return;
      try {
        const { data: resData } = await apiRequest("GET", "/sso/default-list");
        setSsos(resData.data);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoadingSsos(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?._id]);

  const manageLockout = (data, intervalId) => {
    setLockout(data?.timer || 60);
    if (intervalId) clearInterval(intervalId);

    const intervalDataId = setInterval(() => {
      setLockout((cur) => {
        if (cur) {
          return cur - 1;
        } else {
          clearInterval(intervalDataId);
          return cur;
        }
      });
    }, 1000);

    setIntervalId(intervalDataId);
  };

  const trackLogin = async (message = "") => {
    try {
      if (!message) setLoadingTrackLogin(true);
      const { data: resData } = await apiRequest("GET", "/portal/login/tracking/count");

      if (resData.status === 200 && resData?.data?.attemp_count > 10 && resData?.data?.timer !== 0) {
        manageLockout(resData.data);
      }
    } catch (err) {
    } finally {
      if (!message) setLoadingTrackLogin(false);
      if (message) {
        setDisabled(false);
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    trackLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingSsos) {
      if (ssos.length > 0 && site.dual_signin_for_user === false) {
        if (site.auth0_verification_url && site.auth0_access_token_field_name) {
          auth.verifySSOAccessToken(site.auth0_access_token_field_name).then((data) => {
            if (data.status !== 200) {
              // If it is home page and sso relay url exists redirect otherwise login
              ["/"].includes(window.location.pathname) ? (window.location.href = `${apiUrl}/login-sso?RelayState=${window.location.origin}`) : setIsPreloader(false);
            }
          });
        } else {
          window.location.href = `${apiUrl}/login-sso?RelayState=${window.location.origin}`;
        }
      } else {
        setIsPreloader(false);
      }
    }
  }, [loadingSsos, site.auth0_verification_url, site.auth0_access_token_field_name, site.dual_signin_for_user, ssos.length]);

  const clickWorkspace = async () => {
    try {
      setDisabled(true);
      const { data: resData } = await apiRequest("GET", `/portal/users/workspaces/page`);

      if (resData.status === 200) {
        toast.success("Successfully logged in.");
        navigate(resData.link);
      } else {
        toast.error("No workspace found.");
        navigate("/permission-denied");
      }
    } catch (err) {
      toast.error(err.message);
      navigate("/permission-denied");
    } finally {
      setDisabled(false);
    }
  };

  const redirectToWorkspace = async (userId) => {
    if (!userId) {
      navigate("/permission-denied");
      return;
    }

    try {
      setDisabled(true);
      await clickWorkspace();
    } catch (err) {
      navigate("/permission-denied");
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    loadSso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSso]);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (disabled || lockout || otpSecond > 0) return;

      setDisabled(true);
      const { data: resData } = await apiRequest("post", "/portal/auth-login", {
        body: {
          email,
          password,
        },
      });

      if (resData.status === 200) {
        if (resData.requireTwoFactor) {
          setResendOtpSecond(resData?.two_factor_otp_resend_interval_timeframe);
          setRequireTwoFactor(true);
          setDisabled(false);
        } else if (resData?.data?.seconds && resData?.data?.seconds > 0) {
          setOtpSecond(resData?.data?.seconds);
          toast.error(resData.message);
        } else {
          localStorage.setItem("email", resData?.email);
          localStorage.setItem("accessToken", resData.access_token);
          localStorage.setItem("refreshToken", resData.refresh_token);
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            await redirectToWorkspace(resData.userId);
          }
        }
      } else {
        const attemp_count_data = resData?.attemp_count_data || { attemp_count: 0 };

        if (attemp_count_data?.attemp_count > 10) {
          manageLockout(attemp_count_data, intervalId);
        }

        toast.error(resData.message);
      }
    } catch (err) {
      trackLogin(err.message);
    }
  };

  const verifyOtp = async (e) => {
    try {
      e.preventDefault();
      if (disabled || otpSecond > 0) return;

      setDisabled(true);
      const { data: resData } = await apiRequest("post", "/portal/otp/verify", {
        body: {
          email,
          password,
          otp,
        },
      });

      if (resData?.status === 429) {
        setOtpSecond(resData?.data.seconds);
        toast.error(resData.message);
      } else if (resData.status === 200) {
        localStorage.setItem("email", resData?.email);
        localStorage.setItem("accessToken", resData.access_token);
        localStorage.setItem("refreshToken", resData.refresh_token);
        await redirectToWorkspace(resData.userId);
      } else {
        toast.error(resData.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisabled(false);
    }
  };

  const resendOtp = async (e) => {
    try {
      e.preventDefault();
      setDisabledResendOtp(true);
      const { data: resData } = await apiRequest("post", "/portal/resend/otp", { body: { email, password } });
      setResendOtpSecond(resData?.data?.seconds);
      // toast.success(resData.message);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisabledResendOtp(false);
    }
  };

  useEffect(() => {
    if (otpSecond > 0) {
      const timer = setInterval(() => {
        setOtpSecond((prev) => {
          if (prev === 1) {
            clearInterval(timer); // Clear interval when countdown ends
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [otpSecond]);

  useEffect(() => {
    if (resendOtpSecond > 0) {
      const timer = setInterval(() => {
        setResendOtpSecond((prev) => {
          if (prev === 1) {
            clearInterval(timer); // Clear interval when countdown ends
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [resendOtpSecond]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  if (isPreloader) {
    return (
      <div className="flex h-full items-center justify-center">
        <Preloader />
      </div>
    );
  }

  return (
    <AuthDataLayout
      title={site?.login_page_title}
      subTitle={site?.login_page_subtitle}>
      <div className="flex w-full flex-col">
        {!requireTwoFactor ? (
          <form className="flex w-full flex-col gap-y-2">
            <div className="w-full">
              <Input
                inline={true}
                labelClasses={"font-medium mb-0"}
                inputValue={email}
                onChange={(e) => setEmail(e.target.value)}
                label={"Email"}
                autoComplete="username"
                inputType={"email"}
                inputName={"email"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit(e);
                  }
                }}
              />
            </div>
            <div className="w-full">
              <Input
                inline={true}
                labelClasses={"font-medium mb-0"}
                inputValue={password}
                onChange={(e) => setPassword(e.target.value)}
                label={"Password"}
                autoComplete="current-password"
                inputType={"password"}
                inputName={"password"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit(e);
                  }
                }}
              />
            </div>
          </form>
        ) : (
          <div className="w-full">
            <div>
              <p className="mb-1 text-sm text-gray-700">
                <EnvelopeOpenIcon className="mb-[1px] mr-2 inline-flex h-4 w-4 stroke-2" /> If an account is linked to <span className="font-medium">{email}</span>, a validation code will be sent to your email inbox.
              </p>
              <p className="m-6 ml-0 flex text-sm text-gray-700">
                {disabledResendOtp || resendOtpSecond > 0 ? (
                  <p className="ml-1">
                    Please wait <ClockIcon className="mb-[2px] mr-[1px] inline-flex h-4 w-4 stroke-[3px]" />
                    <span className="pr-[1px] font-semibold">{formatTime(resendOtpSecond)}</span> to resend another OTP
                  </p>
                ) : (
                  <>
                    Didn't recieve a code?
                    <Button
                      type="button"
                      buttonClasses="transition-all !h-auto !bg-transparent !py-0 !px-1"
                      buttonLabelClasses="text-sm"
                      buttonLabel={<span className="text-gray-700 underline hover:text-gray-900">Resend</span>}
                      buttonFunction={resendOtp}
                      isDisable={disabledResendOtp || resendOtpSecond > 0}
                    />
                    verification email
                  </>
                )}
              </p>
            </div>
            <form>
              <Input
                inputValue={otp}
                onChange={(e) => {
                  if (e.target.value && !/^[0-9]+$/.test(e.target.value)) {
                    return;
                  } else if (e.target.value.length > 6) {
                    return;
                  }
                  setOtp(e.target.value);
                }}
                label={"OTP"}
                inputType={"text"}
                inputName={"otp"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    verifyOtp(e);
                  }
                }}
              />
            </form>
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <Button
          buttonClasses={"!px-0 !h-auto !bg-transparent !rounded-none !text-sm !my-2"}
          buttonHasLink={true}
          buttonLink={"/forgot-password"}
          buttonLabel={"Forgot Password?"}
          buttonLabelClasses={"!text-sm hover:!underline !text-slate-800 !font-medium"}
        />
      </div>
      <div className="mt-2 grid gap-y-4">
        {!loadingTrackLogin && (
          <Button
            isDisable={disabled || lockout || otpSecond > 0}
            buttonClasses={"w-full"}
            buttonType={"Submit"}
            buttonLabelClasses={" !font-normal "}
            buttonLabel={!lockout ? (otpSecond > 0 ? `You've been blocked for ${formatTime(otpSecond)}` : requireTwoFactor ? "Submit" : "Login") : `Your login has been blocked for ${lockout === 60 ? "01:00" : `00:${lockout < 10 ? "0" : ""}${lockout}`}`}
            buttonFunction={(e) => {
              e.preventDefault();
              !requireTwoFactor ? onSubmit(e) : verifyOtp(e);
            }}
            buttonStyles={{
              borderColor: site?.highlight_color,
              backgroundColor: site?.highlight_color,
            }}
          />
        )}
      </div>
      {ssos.length > 0 && (
        <>
          <div className="my-5 flex items-center justify-between">
            <div className="h-[1px] w-1/4 bg-gray-300"></div>
            <span className="text-xs text-gray-500 sm:text-sm">Or continue with SSO</span>
            <div className="h-[1px] w-1/4 bg-gray-300"></div>
          </div>
          {ssos.map((item) => (
            <div
              key={item._id}
              className="mt-4">
              <Button
                buttonHasLink={false}
                buttonClasses={"w-full"}
                buttonType={"button"}
                buttonLabel={site.sso_button_text ? site.sso_button_text : item.provider_name}
                buttonLabelClasses={""}
                buttonStyles={{
                  borderColor: site?.sso_button_text_color,
                  color: site?.sso_button_text_color || "#fff",
                  backgroundColor: site?.sso_button_color,
                }}
                buttonFunction={() => (window.location.href = `${apiUrl}/login-sso?RelayState=${window.location.origin}`)}
              />
            </div>
          ))}
        </>
      )}
    </AuthDataLayout>
  );
};

export default Login;
