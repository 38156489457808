// import { useContext, useEffect, useRef, useState } from "react";
// import { SiteContext } from "src/context/site";
// import { WorkspaceContext } from "src/context/workspace";
// import { classNames } from "src/helpers/classNames";
// import useIframeResize from "src/hooks/useIframeResize";
// import { apiUrl } from "src/config/host";
// import { v4 as uuidv4 } from "uuid";

// export default function DomoEmbedProgrammaticFiltering({ content }) {
//   const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
//   const { site } = useContext(SiteContext);
//   const { maxWidth } = useIframeResize({ workspaceDetails, site });

//   const [domoOrigin, setDomoOrigin] = useState("");
//   const iframeRef = useRef(null); // Ref to access the iframe

//   const conditionalMaxWidth = content?.embed_type === "EMBED_APP" ? "100%" : maxWidth;

//   // ✅ Move function outside useEffect so it is accessible
//   const findIframeAndSendAuthMessage = () => {
//     const iframe = document.querySelector("iframe"); // Find the iframe manually
//     if (iframe) {
//       // console.log("[PARENT] Found iframe:", iframe);
//       iframeRef.current = iframe; // Assign to ref manually
//       sendAuthMessage();
//     } else {
//       console.warn("[PARENT] ERROR: Iframe not found");
//     }
//   };

//   const sendAuthMessage = () => {
//     if (!domoOrigin) {
//       console.error("[PARENT] ERROR: domoOrigin is undefined, not sending postMessage.");
//       return;
//     }

//     if (!iframeRef.current?.contentWindow) {
//       console.error("[PARENT] ERROR: iframeRef.current.contentWindow is null or undefined.");
//       return;
//     }

//     const referenceId = uuidv4();
//     const authData = {
//       data: {
//         type: "message",
//       },
//       apiUrl,
//       refreshToken: localStorage.getItem("refreshToken"),
//       referenceId,
//     };
//     // console.log("[PARENT] iframeRef.current.contentWindow", iframeRef.current.contentWindow);
//     console.log(`[PARENT] Sending authData to iframe at ${domoOrigin}:`, authData);

//     // iframeRef.current.contentWindow.postMessage(authData, domoOrigin);
//     iframeRef.current.contentWindow.postMessage(authData, "*");
//     iframeRef.current.contentWindow.postMessage(apiUrl, "*");

//   };

//   useEffect(() => {
//     if (domoOrigin) {
//       console.log("[PARENT] Sending auth message...");
//       findIframeAndSendAuthMessage();
//     }
//   }, [domoOrigin]);

//   useEffect(() => {
//     const handleAuthMessage = (event) => {
//       console.log("[PARENT] Received message:", event);

//       if (event.origin.includes("domoapps.prod5.domo.com")) {
//         console.log("[PARENT] Received valid origin:", event.origin);

//         if (event.data?.type === "ready") {
//           console.log("Setting domoOrigin:", event.origin);
//           setDomoOrigin(event.origin); // This update is asynchronous
//         }
//       }
//     };

//     window.addEventListener("message", handleAuthMessage);

//     return () => {
//       window.removeEventListener("message", handleAuthMessage);
//     };
//   }, []);

//   return (
//     <div className={classNames("relative h-full w-full", menuType === "horizontal" || menuType === "vertical" ? "" : "")}>
//       {content?.iframeHtml && (
//         <div
//           className="mx-auto h-full w-full overflow-auto"
//           style={{ maxWidth: conditionalMaxWidth }}
//           dangerouslySetInnerHTML={{ __html: content?.iframeHtml }}
//         />
//       )}
//     </div>
//   );
// }

import { useContext } from "react";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";
import useIframeResize from "src/hooks/useIframeResize";

export default function DomoEmbedProgrammaticFiltering({ content }) {
  const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const { maxWidth } = useIframeResize({ workspaceDetails, site, content });

  // Define the conditional maxWidth value
  const conditionalMaxWidth = content?.embed_type === "EMBED_APP" ? "100%" : maxWidth;

  return (
    <>
      <div className={classNames("relative h-full w-full", menuType === "horizontal" || menuType === "vertical" ? "" : "")}>
        {content?.iframeHtml && (
          <div
            className="mx-auto h-full w-full overflow-auto"
            style={{ maxWidth: conditionalMaxWidth }}
            dangerouslySetInnerHTML={{ __html: content?.iframeHtml }}
          />
        )}
      </div>
    </>
  );
}
